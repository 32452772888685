<template>
  <section class="cont">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>APP管理版本</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
    <el-row class="content-box">
      <!-- 查询框 -->
      <el-row class="search-box">
             <el-row class="search-row">
                <el-row class="search-item">
                   <label>版本名称：</label>
                   <el-input class="width-220" v-model="versionName" :placeholder="$t('msg.version_name')" clearable></el-input>
                </el-row>
                <el-row class="search-item">
                   <label v-text="$t('msg.application_type_a')">应用类型：</label>
                   <el-select class="width-220" v-model="appCategory"  :placeholder="$t('msg.select')">
                      <el-option
                            v-for="item in appCategories"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                      </el-option>
                   </el-select>
                </el-row>
                <el-row class="search-item">
                   <label>APP类型：</label>
                   <el-select class="width-220" v-model="appType"  :placeholder="$t('msg.select')">
                      <el-option
                            v-for="item in appTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                      </el-option>
                   </el-select>
                </el-row>
                <el-button class="bg-gradient"  type="primary" icon="el-icon-search"  @click="handleQuery"><span v-text="$t('msg.search')">搜索</span></el-button>
                <el-button   type="primary" icon="el-icon-refresh-right"  plain @click="handleReset"><span v-text="$t('msg.reset')">重置</span></el-button>
             </el-row>
             <el-row class="search-row">
                <el-row class="search-item">
                   <label v-text="$t('msg.status_a')">状态：</label>
                   <el-button-group class="m-right-10">
                      <el-button  :class="appState === '' ? 'bg-gradient' : ''" @click="queryState('')" ><span>全部</span></el-button>
                      <el-button  :class="appState === 'UNRELEASED' ? 'bg-gradient' : ''" @click="queryState('UNRELEASED')" ><span v-text="$t('msg.unpublished')"></span></el-button>
                      <el-button  :class="appState === 'RELEASE' ? 'bg-gradient' : ''" @click="queryState('RELEASE')" ><span v-text="$t('msg.published')"></span></el-button>
                      <el-button  :class="appState === 'SOLD_OUT' ? 'bg-gradient' : ''" @click="queryState('SOLD_OUT')" ><span v-text="$t('msg.removed_from_shelf')"></span></el-button>
                   </el-button-group>
                </el-row>
             </el-row>
          </el-row>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row></el-row>
          <el-row class="right-btn">
            <el-button  class="bg-gradient" type="primary" icon="el-icon-plus"  style="float: right" @click="handleAdd"><span v-text="$t('msg.new_version')"></span></el-button>
          </el-row>
        </div>
        <!-- 主体内容 -->
        <div class="table-box">
          <el-table
              ref="mainTable"
              v-loading="loading"
              :data="tableData"
              border fit
              style="width: 100%"
              :stripe="true"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column label="序号" min-width="60">
              <template slot-scope="scope">
                {{scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column label="icon" min-width="60">
              <template slot-scope="scope">
                <el-popover
                    placement="right"
                    @show="getAppQRCode(scope.row.id)"
                    trigger="click">
                  <el-row>
                    <p style="text-align: center">
                      <img width="180" :src="scope.row.QRcode" alt="">
                    </p>
                    <p class="m-bottom-5">
                      <span v-text="$t('msg.version_name_a')"></span>
                      <i>{{scope.row.versionName}}</i>
                    </p>
                    <p class="m-bottom-5">
                      <span v-text="$t('msg.version_no_a')"></span>
                      <i>{{scope.row.versionCode}}</i>
                    </p>
                    <p>
                      <span v-text="$t('msg.release_time_a')"></span>
                      <i>{{scope.row.releaseTime}}</i>
                    </p>
                    <a class="el-icon-download download" :href="scope.row.QRcode" download="img"></a>
                  </el-row>
                  <span slot="reference" class="icon iconfont appIcon">{{scope.row.appType === 'ANDROID'?'&#xe60b;':'&#xe634;'}}</span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="appCategory" min-width="80" :label="$t('msg.application_type')"></el-table-column>
            <el-table-column prop="versionName" min-width="70" :label="$t('msg.version_name')"></el-table-column>
            <el-table-column prop="versionCode" min-width="60" :label="$t('msg.version_no')"></el-table-column>
            <el-table-column :label="$t('msg.status')" min-width="50">
              <template slot-scope="scope">
                <span>{{scope.row.appState | filterAppState(that)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="releaseTime" :label="$t('msg.release_time')" min-width="120"></el-table-column>
            <el-table-column prop="soldOutTime" :label="$t('msg.off_shelf_time')" min-width="120"></el-table-column>
            <el-table-column prop="filePath" :label="$t('msg.file_path')" min-width="150"></el-table-column>
            <el-table-column show-overflow-tooltip prop="remark" :label="$t('msg.remark')" min-width="200"></el-table-column>
            <el-table-column :label="$t('msg.operating')" min-width="140" sortable fixed="right">
              <template slot-scope="scope">
                <el-button v-show="scope.row.appState === 'UNRELEASED'" @click="handleEdit(scope.row)" type="text" >
                  <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                </el-button>
                <el-button v-show="scope.row.appState === 'SOLD_OUT' || scope.row.appState === 'UNRELEASED'" style="color: #F56C6C;" @click="handleDel(scope.row.id)" type="text" >
                  <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                </el-button>
                <el-button v-show="scope.row.appState === 'UNRELEASED'" @click="changeAppStatus(scope.row.id,'RELEASE')" type="text">
                  <el-link type="primary" v-text="$t('msg.release')">发布</el-link>
                </el-button>
                <el-button v-show="scope.row.appState === 'RELEASE'" @click="changeAppStatus(scope.row.id,'SOLD_OUT')" type="text">
                  <el-link type="primary" v-text="$t('msg.lower_shelf')">下架</el-link>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
           </div>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
      <!-- 新增/编辑弹窗 -->
      <el-dialog :title="`${ action === 'add' ? '添加' : '编辑' }APP`" :before-close="handleClose" :visible.sync="isShow" width="550px">
        <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="应用类型：">
            <el-select v-model="ruleForm.appCategory"  class="width-200" :placeholder="$t('msg.select')">
              <el-option label="APP酒店" value="APP_HOTEL"></el-option>
              <el-option label="APP家居" value="APP_HOME"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="版本名称：" prop="versionName">
            <el-input  class="width-200" v-model="ruleForm.versionName" :placeholder="$t('msg.version_name')" clearable></el-input>
          </el-form-item>
          <el-form-item label="版本号：" prop="versionCode">
            <el-input  class="width-200" v-model="ruleForm.versionCode" :placeholder="$t('msg.version_no')" clearable></el-input>
          </el-form-item>
          <el-form-item label="App 类型：">
            <el-radio-group v-model="ruleForm.appType">
              <el-radio label="ANDROID">安卓</el-radio>
              <el-radio label="IPHONE">苹果</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="App 安装文件：">
            <el-upload
                name="file" class="upload-demo" :auto-upload="false"
                action="https://jsonplaceholder.typicode.com/posts/" multiple="false" :on-change="selectFileFn" :limit="1">
              <el-button  type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="备注信息：">
            <el-input class="width-200 type-textarea" type="textarea" v-model="ruleForm.remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
          <el-button  type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
        </span>
      </el-dialog>
    </el-row>
  </section>
</template>

<script>
import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import { mapState } from 'vuex'
   import { getDict } from '@/common/js/common'
export default {
      data(){
         return{
            tableData: [],       // 表格数据
            isShow: false,       // 是否显示弹窗
            id: '',              // 编辑用id
            total: 0,            // 总条数
            loading: true,       // 加载中效果
            action: 'add',       // 操作行为
            QRcode: '',          // 二维码URL
            that: this,
            appCategories:[      // APP分类
               {
                  value: '',
                  label: '全部'
               }, {
                  value: 'APP_HOTEL',
                  label: 'APP酒店'
               },  {
                  value: 'APP_HOME',
                  label: 'APP家居'
               }
            ],
            appTypes: [
               {
                  value: '',
                  label: '全部'
               }, {
                  value: 'ANDROID',
                  label: '安卓'
               }, {
                  value: 'IPHONE',
                  label: '苹果'
               }
            ],
            // 查询参数
            page: 1,             // 当前页 默认第1页
            limit: 0,            // 每页显示数
            versionName: '',     // 版本名称
            appCategory: '',     // 应用类型
            appType: '',         // APP类型
            appState: '',        // APP状态
            file: '',            // APP安装文件
            ruleForm: {
               appCategory: 'APP_HOTEL',
               versionName: '',
               versionCode: '',     // APP版本号
               appType: 'ANDROID',  // APP类型
               remark: '',          // 备注
            },
            rules: {
               versionName: [
                  { required: true, message: '请输入版本名称', trigger: 'blur' }
               ],
               versionCode: [
                  { required: true, message: '请输入版本号', trigger: 'blur' }
               ]
            },
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！',
            release_success: '发布成功！'
         }
      },
      computed: {
         ...mapState(['dictData'])
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.initForm = { ...this.ruleForm }
         getDict(['app-status'])
         this.getApps()
      },
      methods: {
         // 获取APP版本列表
         getApps(){
            const param = {
               page: this.page,
               limit: this.limit,
               versionName: this.versionName,
               appCategory: this.appCategory,
               appType: this.appType,
               appState: this.appState,
            }
            const url = system.appVersion
            this.$axios.post(url, param).then((res) => {
               if(res.success){
                  this.total = res.total
                  this.loading = false
                  this.tableData = res.records
               }
            })
         },
         // 搜索APP名称
         handleQuery(){
            this.getApps()
         },
         // 查询app版本状态
         queryState(val){
            this.appState = val
         },
         // 重置搜索
         handleReset(){
            this.versionName = ''
            this.appCategory = ''
            this.appType = ''
            this.appState = ''
            this.getApps()
         },
         // 改变显示条数
         pageChange(num){
            this.limit = num
            this.getApps()
         },
         // 改变当前页
         handlePaging(page){
            this.page = page
            this.getApps()
         },
         selectFileFn(file){
            this.file = file.raw
         },
         // 添加APP版本
         handleAdd(){
            this.isShow = true
            this.action = 'add'
         },
         // 编辑菜单
         handleEdit(row){
            for (let k in row) k in this.ruleForm && (this.ruleForm[k] = row[k])
            this.isShow = true
            this.action = 'edit'
            this.id = row.id
         },
         // 重置表单
         resetForm(){
            this.file = null
            this.ruleForm = { ...this.initForm }
         },
         // 保存菜单
         handleSave(){
            this.$refs.ruleForm.validate(valid => {
               if (!valid) return
               let func = this.ruleForm.appCategory.split('_')[1].toLowerCase()
               let url = system.addAppVersion
               let param = new FormData()
               if (this.file) {
                  param.append('file', this.file)
                  param.append('module', 'system')
                  param.append('menu', 'app')
                  param.append('func', func)
                  param.append('category', 'vserion')
               }
               for (let key in this.ruleForm) param.append(key, this.ruleForm[key])
               if (this.action === 'edit'){
                  url = system.editAppVersion
                  param.append('id', this.id)
               }
               this.$axios.post(url, param, 'file').then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: `${this.action === 'add' ? '添加' : '编辑'}成功`,
                        type: 'success'
                     })
                     this.handleClose()
                     this.getApps()
                  }
               })
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.resetForm()
         },
         // 删除APP版本
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let ids = []; ids.push(id)
               const param = { ids: ids.toString() }
               const url = system.delAppVersion
               this.$axios.post(url,param).then(res => {
                  if (res.success){
                     this.$message({
                        showClose: true,
                        message: this.del_success,
                        type: 'success'
                     })
                     this.getApps()
                  }
               })
            })
         },
         // 发布/下架App版本
         changeAppStatus(id, val){
            let ids = []; ids.unshift(id)
            const param = { ids: ids.toString(), appStatus: val }
            const url = system.changeAppStatus
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.release_success,
                     type: 'success'
                  })
                  this.getApps()
               }
            })
         },
         // 获取文件二维码
         getAppQRCode(id){
            const url = system.getAppQRCode
            const param = { id, isBase64Img: true }
            this.$axios.get(url, param).then(res => {
               if (res){
                  this.tableData.forEach(item => {
                     const QRcode = item.id === id && `data:image/png;base64,${res}`
                     this.$set(item, 'QRcode', QRcode)
                  })
               }
            })
         }
      },
      filters: {
         filterAppState(val, that){
            if (val && that.dictData['app-status']) return that.dictData['app-status'][val] || val
         }
      }
   }
</script>

<style scoped>
   .download{
      font-size: 1.6rem;
      color: #1ABC9C;
      text-decoration: none;
      float: right;
      margin: 0.3rem 0;
   }
   .appIcon{
      cursor: pointer;
      color: #1ABC9C;
      font-size: 1.4rem;
      margin-right: 4px
   }
   .type-textarea{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
   }
</style>
